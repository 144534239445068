import React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout>
    <section className="hero is-info is-large">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">Oopsie Doopsie!!1!</h1>
          <h2 className="subtitle">Page not found. You know the drill.</h2>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
